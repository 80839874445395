import caas from "@/services/caas";
import catalogItemWf from "@/store/modules/catalogItemWf.json";
import { findIndex, findLastIndex } from "lodash/findLastIndex";
// STATE
const state = {
    model: {
        ecatItem: {
            rowObject: {},
            ecatItemImg: {
                rowObject: [],
            },
            ecatItemInfo: {
                rowObject: {},
            },
            ecatItemAttr: {
                rowObject: [],
            },
            ecatItemLabel: {
                rowObject: [],
            },
            ecatItemSku: {
                rowObject: [],
            },
            ecatItemLink: {
                rowObject: [],
            },
            ecatEntry: {
                rowObject: [],
            },
        },
        brands: [],
        labels: [],
        attributes: [],
    },
    view: {
        catalogItemId: 0,
        loading: false,
        itemList: [],
    },
    catalogItemWf: catalogItemWf,
};

const actions = {
    fetchAttributes(context) {
        const sdoName = "web/objects/dECatAttr.r";
        const params = {
            action: "read",
            nbrOfRows: 9999,
            setOpenQuery: "for each ecat_attr no-lock indexed-reposition",
        };
        context.commit("SET_LOADING", true);
        caas.sdo(sdoName, params)
            .then((response) => {
                if (response.data.success) {
                    context.commit(
                        "SET_ECAT_ATTRIBUTES",
                        response.data.success.data.RowObject
                    );
                }
            })
            .finally(() => {
                context.commit("SET_LOADING", false);
            });
    },
    fetchBrands(context) {
        const sdoName = "web/objects/dECatBrand.r";
        const params = {
            action: "read",
            nbrOfRows: 9999,
            setOpenQuery: "for each ecat_brand no-lock indexed-reposition",
        };
        context.commit("SET_LOADING", true);
        caas.sdo(sdoName, params)
            .then((response) => {
                if (response.data.success) {
                    context.commit(
                        "SET_ECAT_BRANDS",
                        response.data.success.data.RowObject
                    );
                }
            })
            .finally(() => {
                context.commit("SET_LOADING", false);
            });
    },
    fetchItemCategories(context) {
        const sdoName = "web/objects/dECatEntry.r";
        const params = {
            action: "read",
            nbrOfRows: 9999,
            setOpenQuery:
                "for each ecat_entry where ecat_entry.item_id=" +
                state.view.catalogItemId +
                " no-lock indexed-reposition",
        };
        context.commit("SET_LOADING", true);
        caas.sdo(sdoName, params)
            .then((response) => {
                if (response.data.success) {
                    context.commit(
                        "SET_ECAT_ITEM_CATEGORIES",
                        response.data.success.data.RowObject
                    );
                }
            })
            .finally(() => {
                context.commit("SET_LOADING", false);
            });
    },
    fetchLabels(context) {
        const sdoName = "web/objects/dECatLabel.r";
        const params = {
            action: "read",
            nbrOfRows: 9999,
            setOpenQuery: "for each ecat_label no-lock indexed-reposition",
        };
        context.commit("SET_LOADING", true);
        caas.sdo(sdoName, params)
            .then((response) => {
                if (response.data.success) {
                    context.commit(
                        "SET_ECAT_LABELS",
                        response.data.success.data.RowObject
                    );
                }
            })
            .finally(() => {
                context.commit("SET_LOADING", false);
            });
    },
    fetchItem(context) {
        const sdoName = "web/objects/dECatItem.r";
        const params = {
            action: "read",
            nbrOfRows: 1,
            setOpenQuery:
                "for each ecat_item where item_id = " +
                state.view.catalogItemId +
                " no-lock indexed-reposition",
        };
        context.commit("SET_LOADING", true);
        caas.sdo(sdoName, params)
            .then((response) => {
                if (response.data.success) {
                    context.commit(
                        "SET_ECAT_ITEM",
                        response.data.success.data.RowObject[0]
                    );
                }
            })
            .finally(() => {
                context.commit("SET_LOADING", false);
            });
    },
    fetchItemImages(context) {
        const sdoName = "web/objects/dECatItemImg.r";
        const params = {
            action: "read",
            nbrOfRows: 999,
            setOpenQuery:
                "for each ecat_item_img where item_id = " +
                context.state.view.catalogItemId +
                " and item_sku = '' no-lock indexed-reposition",
            setQuerySort: "by item_seqnr",
        };
        context.commit("SET_LOADING", true);
        caas.sdo(sdoName, params)
            .then((response) => {
                if (response.data.success) {
                    context.commit(
                        "SET_ECAT_ITEM_IMG",
                        response.data.success.data.RowObject
                    );
                }
            })
            .finally(() => {
                context.commit("SET_LOADING", false);
            });
    },
    fetchItemInfo(context) {
        const sdoName = "web/objects/dECatItemInfo.r";
        const params = {
            action: "read",
            nbrOfRows: 999,
            setOpenQuery:
                "for each ecat_item_info where item_id = " +
                state.view.catalogItemId +
                " and lng_id = 1 no-lock indexed-reposition",
        };
        context.commit("SET_LOADING", true);
        caas.sdo(sdoName, params)
            .then((response) => {
                if (response.data.success) {
                    context.commit(
                        "SET_ECAT_ITEM_INFO",
                        response.data.success.data.RowObject[0]
                    );
                }
            })
            .finally(() => {
                context.commit("SET_LOADING", false);
            });
    },
    fetchItemLabels(context) {
        const sdoName = "web/objects/dECatItemLabel.r";
        const params = {
            action: "read",
            nbrOfRows: 999,
            setOpenQuery:
                "for each ecat_item_label where item_id = " +
                state.view.catalogItemId +
                " no-lock indexed-reposition",
        };
        context.commit("SET_LOADING", true);
        caas.sdo(sdoName, params)
            .then((response) => {
                if (response.data.success) {
                    context.commit(
                        "SET_ECAT_ITEM_LABELS",
                        response.data.success.data.RowObject
                    );
                }
            })
            .finally(() => {
                context.commit("SET_LOADING", false);
            });
    },
    fetchItemLinks(context) {
        const sdoName = "web/objects/dECatItemLink.r";
        const params = {
            action: "read",
            nbrOfRows: 999,
            setOpenQuery:
                "for each ecat_item_link where source_item_id = " +
                state.view.catalogItemId +
                " no-lock indexed-reposition",
        };
        context.commit("SET_LOADING", true);
        caas.sdo(sdoName, params)
            .then((response) => {
                if (response.data.success) {
                    context.commit(
                        "SET_ECAT_ITEM_LINKS",
                        response.data.success.data.RowObject
                    );
                }
            })
            .finally(() => {
                context.commit("SET_LOADING", false);
            });
    },
    fetchItemAttributes(context) {
        const sdoName = "web/objects/dECatItemAttr.r";
        const params = {
            action: "read",
            nbrOfRows: 999,
            setOpenQuery:
                "for each ecat_item_attr where item_id = " +
                state.view.catalogItemId +
                " no-lock indexed-reposition",
        };
        context.commit("SET_LOADING", true);
        caas.sdo(sdoName, params)
            .then((response) => {
                if (response.data.success) {
                    context.commit(
                        "SET_ECAT_ITEM_ATTR",
                        response.data.success.data.RowObject
                    );
                }
            })
            .finally(() => {
                context.commit("SET_LOADING", false);
            });
    },
    fetchItemSkuList(context, pItemId) {
        const sdoName = "web/objects/dECatItemSku.r";
        const params = {
            action: "read",
            nbrOfRows: 999,
            setOpenQuery:
                "for each ecat_item_sku where item_id = " +
                context.state.view.catalogItemId +
                " no-lock indexed-reposition",
            setQuerySort: "by item_seq_nr by item_sku",
        };
        caas.sdo(sdoName, params).then((response) => {
            context.commit(
                "setItemSkuList",
                response.data.success.data.RowObject
            );
        });
    },
    setCatalogItemId: (context, pItemId) => {
        context.commit("SET_CATALOG_ITEM_ID", pItemId);
    },
};

const mutations = {
    setItemSkuList: (state, payload) => {
        state.model.ecatItem.ecatItemSku = payload;
    },
    SET_ECAT_ATTRIBUTES: (state, payload) => {
        state.model.attributes = payload;
    },
    SET_ECAT_BRANDS: (state, payload) => {
        state.model.brands = payload;
    },
    SET_ECAT_LABELS: (state, payload) => {
        state.model.labels = payload;
    },
    SET_CATALOG_ITEM_ID: (state, pItemId) => {
        state.view.catalogItemId = pItemId;
    },
    SET_ECAT_ITEM_CATEGORIES: (state, payload) => {
        state.model.ecatItem.ecatEntry.rowObject = payload;
    },
    SET_ECAT_ITEM: (state, payload) => {
        state.model.ecatItem.rowObject = payload;
    },
    SET_ECAT_ITEM_IMG: (state, payload) => {
        state.model.ecatItem.ecatItemImg.rowObject = payload;
    },
    SET_ECAT_ITEM_INFO: (state, payload) => {
        state.model.ecatItem.ecatItemInfo.rowObject = payload;
    },
    SET_ECAT_ITEM_LABELS: (state, payload) => {
        state.model.ecatItem.ecatItemLabel.rowObject = payload;
    },
    SET_ECAT_ITEM_LINKS: (state, payload) => {
        state.model.ecatItem.ecatItemLink.rowObject = payload;
    },
    SET_ECAT_ITEM_ATTR: (state, payload) => {
        state.model.ecatItem.ecatItemAttr.rowObject = payload;
    },

    SET_LOADING: (state, pLoading) => {
        state.view.loading = pLoading;
    },
    SET_ITEM_LIST: (state, payload) => {
        state.view.itemList = payload;
    },
};

const getters = {
    getItemSkuList(state) {
        return state.model.ecatItem.ecatItemSku;
    },
    attributes(state) {
        let arr1 = state.model.attributes;
        let arr2 = state.model.ecatItem.ecatItemAttr.rowObject;
        const diff = arr1.filter(
            ({ attr_id: id1 }) => !arr2.some(({ attr_id: id2 }) => id2 === id1)
        );
        return diff;
    },
    brandNames(state) {
        let arr1 = state.model.brands;
        let arr2 = arr1.map((v) => ({ text: v.brand_name, value: v.brand_id }));
        return arr2;
    },
    labels(state) {
        let arr1 = state.model.labels;
        let arr2 = state.model.ecatItem.ecatItemLabel.rowObject;
        const diff = arr1.filter(
            ({ label_id: id1 }) =>
                !arr2.some(({ label_id: id2 }) => id2 === id1)
        );
        return diff;
    },
    catalogItemId(state) {
        return state.view.catalogItemId;
    },
    itemCategories(state) {
        let x = state.model.ecatItem.ecatEntry.rowObject;
        return x.sort(
            (a, b) => new Date(a.dt_created) - new Date(b.dt_created)
        );
    },
    ecatItem(state) {
        return state.model.ecatItem.rowObject;
    },
    ecatItemImg(state) {
        return state.model.ecatItem.ecatItemImg.rowObject;
    },
    ecatItemInfo(state) {
        return state.model.ecatItem.ecatItemInfo.rowObject;
    },
    ecatItemLabel(state) {
        return state.model.ecatItem.ecatItemLabel.rowObject;
    },
    ecatItemLink(state) {
        return state.model.ecatItem.ecatItemLink.rowObject;
    },
    ecatItemAttr(state) {
        return state.model.ecatItem.ecatItemAttr.rowObject;
    },
    nextItemId(state) {
        let itemList = state.view.itemList;
        let indexOfCurrentItem = _.findLastIndex(
            itemList,
            (i) => i.item_id === state.view.catalogItemId
        );
        if (indexOfCurrentItem < itemList.length - 1) {
            return itemList[indexOfCurrentItem + 1].item_id;
        }
        return null;
    },
    prevItemId(state) {
        let itemList = state.view.itemList;
        let indexOfCurrentItem = _.findIndex(
            itemList,
            (i) => i.item_id === state.view.catalogItemId
        );
        if (indexOfCurrentItem > 0) {
            return itemList[indexOfCurrentItem - 1].item_id;
        }
        return null;
    },
    itemList(state) {
        return state.view.itemList;
    },
    itemHasColors(state) {
        return state.model.ecatItem.rowObject.has_option1;
    },
    itemHasSizes(state) {
        return state.model.ecatItem.rowObject.has_option2;
    },
    itemHasVariants(state) {
        return state.model.ecatItem.rowObject.has_option3;
    },
    wfProcessEnum(state) {
        let arr1 = state.catalogItemWf.wfProcesses;
        if (arr1) {
            let arr2 = arr1.map((v) => ({ text: v.name, value: v.name }));
            return arr2;
        }
    },
    wfStatusEnum: (state) => (process) => {
        let objArr = state.catalogItemWf.wfProcesses;
        let obj1 = objArr.find((obj) => {
            return obj.name === process;
        });
        if (obj1) {
            let arr1 = obj1.wfStatusses;
            if (arr1) {
                let arr2 = arr1.map((v) => ({
                    text: v.name,
                    value: v.name,
                }));
                return arr2;
            }
        }
    },
    wfActionsEnum: (state) => (filter) => {
        let objArr = state.catalogItemWf.wfProcesses;
        let obj1 = objArr.find((obj) => {
            return obj.name === filter.process;
        });
        if (obj1) {
            let arr1 = obj1.wfStatusses;
            if (arr1) {
                let obj2 = arr1.find((obj) => {
                    return obj.name === filter.status;
                });
                if (obj2) {
                    return obj2.wfActions;
                }
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
