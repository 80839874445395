import caas from "@/services/caas";

// STATE
const state = {
    catalogList: [],
    categoryList: [],
    itemList: [],
    ui: {
        loading: false,
        searchText: "",
        currentCatalog: {},
        currentCategory: {},
        currentFilter: {},
    },
};

// ACTIONS
const actions = {
    fetchCatalogList: (context) => {
        let params = {};
        // catalog
        return caas.rpc("getCatalogList", params).then((response) => {
            context.commit(
                "SET_CATALOG_LIST",
                response.data.success.data.catalogList
            );
            context.commit(
                "SET_CURRENT_CATALOG",
                response.data.success.data.catalogList[0]
            );
            //fetch categories of first catalog
            params = {
                catalogId: response.data.success.data.catalogList[0].catalogId,
            };
            caas.rpc("getCategoriesOfCatalog", params).then((response) => {
                context.commit(
                    "SET_CATEGORY_LIST",
                    response.data.success.data.categoryList
                );
            });
        });
    },
    openQuery: (context) => {
        let args = Boolean(
            (context.state.ui.currentCategory &&
                context.state.ui.currentCategory.id > 0) ||
                (context.state.ui.searchText &&
                    context.state.ui.searchText > "") ||
                (context.state.ui.currentFilter &&
                    context.state.ui.currentFilter.value)
        );
        if (args) {
            context.state.ui.loading = true;
            const sdoName = "web/objects/dECatItemList.r";
            const params = {
                action: "read",
                nbrOfRows: 999,
                setOpenQuery: "for each ecat_item no-lock",
                setQuerySort: "by ecat_item.item_id",
            };
            // brand filter
            if (
                context.state.ui.currentFilter &&
                context.state.ui.currentFilter.value
            ) {
                params.setOpenQuery +=
                    " where brand_id=" + context.state.ui.currentFilter.value;
                // search w/ brand filter
                if (context.state.ui.searchText) {
                    params.setOpenQuery +=
                        " and item_name contains '" +
                        context.state.ui.searchText +
                        "*'";
                }
            } else {
                // search w/o brand filter
                if (context.state.ui.searchText) {
                    params.setOpenQuery +=
                        " where item_name contains '" +
                        context.state.ui.searchText +
                        "*'";
                }
            }
            // category filter
            if (context.state.ui.currentCategory.id) {
                params.setOpenQuery +=
                    ", first ecat_entry of ecat_item where index_id=" +
                    state.ui.currentCategory.id +
                    " no-lock indexed-reposition";
            } else {
                params.setOpenQuery +=
                    ", first ecat_entry of ecat_item outer-join no-lock indexed-reposition";
            }
            caas.sdo(sdoName, params).then((response) => {
                if (response.data.success) {
                    context.commit(
                        "SET_ITEM_LIST",
                        response.data.success.data.RowObject
                    );
                    // voor navigatie in catalogitem:
                    context.rootState.catalogItem.view.itemList =
                        response.data.success.data.RowObject;
                }
                context.state.ui.loading = false;
            });
        } else {
            context.commit("SET_ITEM_LIST", []);
        }
    },
    setCurrentCategory: (context, pCategory) => {
        context.commit("SET_CURRENT_CATEGORY", pCategory);
    },
    setSearchText: (context, pSearchText) => {
        context.commit("SET_SEARCH_TEXT", pSearchText);
    },
    setCurrentFilter: (context, pFilter) => {
        context.commit("SET_CURRENT_FILTER", pFilter);
    },
};

// MUTATIONS
const mutations = {
    SET_CATALOG_LIST: (state, payload) => {
        state.catalogList = payload;
    },
    SET_CATEGORY_LIST: (state, payload) => {
        state.categoryList = payload;
    },
    SET_CURRENT_CATALOG: (state, payload) => {
        state.ui.currentCatalog = payload;
    },
    SET_CURRENT_CATEGORY: (state, payload) => {
        state.ui.currentCategory = payload;
    },
    SET_ITEM_LIST: (state, payload) => {
        // state.itemList.push(payload);
        state.itemList = payload;
    },
    SET_SEARCH_TEXT: (state, payload) => {
        state.ui.searchText = payload;
    },
    SET_CURRENT_FILTER: (state, payload) => {
        state.ui.currentFilter = payload;
    },
};

// GETTERS
const getters = {
    currentCategoryName: (state) => {
        return state.ui.currentCategory.name;
    },
    categoryList: (state) => {
        return state.categoryList;
    },
    currentFilter: (state) => {
        return state.ui.currentFilter;
    },
    itemList: (state) => {
        return state.itemList;
    },
    loading: (state) => {
        return state.ui.loading;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
