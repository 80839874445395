import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Login from "@/views/Login";
import Logout from "@/views/Logout";
import Home from "@/views/Home";
import goTo from "vuetify/lib/services/goto";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: { requiresAuth: true },
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: { requiresAuth: false },
    },
    {
        path: "/resetPassword",
        name: "ResetPassword",
        component: lazyLoad("ResetPassword"),
        meta: { requiresAuth: false },
    },
    {
        path: "/logout",
        name: "Logout",
        component: Logout,
        meta: { requiresAuth: true },
    },
    {
        path: "/catalog",
        name: "ProductCatalog",
        component: lazyLoad("ProductCatalog"),
        meta: { requiresAuth: true },
    },
    {
        path: "/categories",
        name: "CatalogIndex",
        component: lazyLoad("CatalogIndex"),
        meta: { requiresAuth: true },
    },
    {
        path: "/catalog/item/:id",
        name: "CatalogItem",
        component: lazyLoad("CatalogItem"),
        props: { id: Number },
        meta: { requiresAuth: true },
    },
    {
        path: "/brand/:id",
        name: "Brand",
        component: lazyLoad("Brand"),
        props: { id: Number },
        meta: { requiresAuth: true },
    },
    {
        path: "/workflows/onboarding",
        name: "Onboarding",
        component: lazyLoad("Onboarding"),
        meta: { requiresAuth: true },
    },
    {
        path: "/brands",
        name: "Brands",
        component: lazyLoad("Brands"),
        meta: { requiresAuth: true },
    },
    {
        path: "/atp",
        name: "ATP",
        component: lazyLoad("ATP"),
        meta: { requiresAuth: true },
    },
];

function lazyLoad(view) {
    return () => {
        return import(`@/views/${view}.vue`);
    };
}

const scrollBehavior = function (to, from, savedPosition) {
    let scrollTo = 0;
    if (to.hash) {
        scrollTo = to.hash;
    } else if (savedPosition) {
        scrollTo = savedPosition.y;
    }
    return setTimeout(function () {
        goTo(scrollTo);
    }, 1000);
};

const router = new VueRouter({
    mode: "history",
    base: "/",
    routes,
    scrollBehavior,
});

// N A V I G A T I O N   G U A R D
router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters["session/sessionActive"]) {
            // there is both a user and a session available
            // carry on ..
            next();
        } else {
            if (store.getters["session/firebaseUserId"]) {
                // we have a user but no session available
                // start a session
                store.dispatch("session/startWebSession").then(() => {
                    if (store.getters["session/webSessionId"]) {
                        // we successfully started a session
                        next();
                    } else {
                        store.dispatch(
                            "session/showBottomMessage",
                            "Er is iets misgelopen"
                        );
                        next({ name: "Login" });
                    }
                });
            } else {
                // no user available
                next({ name: "Login" });
            }
        }
    } else {
        // if no auth required
        next();
    }
});

router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title =
            to.meta.title ||
            process.env.VUE_APP_TENANT_NAME +
                " " +
                process.env.VUE_APP_APP_NAME;
    });
});

export default router;
