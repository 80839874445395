import Vue from "vue";
import Vuex from "vuex";
import session from "@/store/modules/session";
import catalog from "@/store/modules/catalog";
import catalogItem from "@/store/modules/catalogItem";
import catalogIndex from "@/store/modules/catalogIndex";

Vue.use(Vuex);
const store = new Vuex.Store({
    modules: {
        session,
        catalog,
        catalogIndex,
        catalogItem,
    },
});
export default store;
