import caas from "@/services/caas";

// STATE
const state = {
    currentIndex: 0,
    catalogIndex: {},
    catalogDescription: {},
    catalogEntries: [],
};

const actions = {
    fetchCatalogIndex(context) {
        let sdoName = "web/objects/dECatIndex.r";
        let params = {
            action: "read",
            nbrOfRows: 1,
            setOpenQuery:
                "for each ecat_index where catalog_id = 1 and index_id = " +
                context.state.currentIndex +
                " no-lock indexed-reposition",
        };
        caas.sdo(sdoName, params).then((response) => {
            if (response.data.success) {
                context.commit(
                    "setCatalogIndex",
                    response.data.success.data.RowObject[0]
                );
            }
        });
    },
    fetchCatalogDescription(context) {
        let sdoName = "web/objects/dECatIndexDescr.r";
        let params = {
            action: "read",
            nbrOfRows: 1,
            setOpenQuery:
                "for each ecat_index_descr where index_id = " +
                context.state.currentIndex +
                " and lng_id = 1 no-lock indexed-reposition",
        };
        caas.sdo(sdoName, params).then((response) => {
            if (response.data.success) {
                context.commit(
                    "setCatalogDescription",
                    response.data.success.data.RowObject[0]
                );
            }
        });
    },
    fetchCatalogEntries(context) {
        let sdoName = "web/objects/dECatEntry.r";
        let params = {
            action: "read",
            nbrOfRows: 999,
            setOpenQuery:
                "for each ecat_entry where index_id = " +
                context.state.currentIndex +
                " no-lock indexed-reposition",
        };
        caas.sdo(sdoName, params).then((response) => {
            if (response.data.success) {
                context.commit(
                    "setCatalogEntries",
                    response.data.success.data.RowObject
                );
            }
        });
    },
};

const mutations = {
    setCatalogIndex(state, payload) {
        state.catalogIndex = payload;
    },
    setCatalogDescription(state, payload) {
        state.catalogDescription = payload;
    },
    setCatalogEntries(state, payload) {
        state.catalogEntries = payload;
    },
    setCurrentIndex(state, payload) {
        state.currentIndex = payload;
    },
};

const getters = {
    getCatalogIndexId(state) {
        return state.currentIndex;
    },
    getCatalogIndex(state) {
        return state.catalogIndex;
    },
    getCatalogDescription(state) {
        return state.catalogDescription;
    },
    getCatalogEntries(state) {
        return state.catalogEntries;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
