import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { signOut } from "firebase/auth";
import { sendPasswordResetEmail } from "firebase/auth";
import { getStorage } from "firebase/storage";

// init app
const firebaseConfig = {
    apiKey: "AIzaSyBhaI1HfJHgc357NU-hCuNSg7Zeweq_vEo",
    authDomain: "continuans.firebaseapp.com",
    databaseURL: "https://continuans.firebaseio.com",
    projectId: "continuans",
    storageBucket: "continuans.appspot.com",
    messagingSenderId: "567867533257",
    appId: "1:567867533257:web:3ca63c2e41a2f0a039ea18",
};
initializeApp(firebaseConfig);

// add auth to app
const fireAuth = getAuth();

export {
    fireAuth,
    signInWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail,
    getStorage,
};
