<template>
	<v-app id="app">
		<!-- NAVDRAWER  -->
		<v-navigation-drawer
			style="z-index: 11"
			v-model="drawer"
			v-if="sessionActive"
			app
			:mini-variant="miniVariant"
			floating
		>
			<v-toolbar flat dense>
				<v-icon @click="toggleVariant">mdi-menu</v-icon>
			</v-toolbar>
			<smart-nav-bar class="text-subtitle-1" :navItems="navItems" />
		</v-navigation-drawer>

		<!-- APP BAR -->
		<v-app-bar app v-if="sessionActive" flat class="white">
			<v-app-bar-nav-icon
				v-if="$vuetify.breakpoint.mobile"
				@click="toggleDrawer"
			></v-app-bar-nav-icon>
			<v-spacer />

			<v-img src="@/assets/logo.png" height="40" contain />

			<v-spacer />
			<v-btn icon plain :to="{ name: 'Logout' }">
				<v-icon>mdi-logout</v-icon>
			</v-btn>
		</v-app-bar>

		<!-- MAIN -->
		<v-main v-resize="onResize" v-scroll="onScroll">
			<transition name="fade" mode="out-in">
				<keep-alive>
					<router-view> </router-view>
				</keep-alive>
			</transition>
		</v-main>

		<!-- SCROLL TOP -->
		<v-btn
			class="mb-12"
			v-if="sessionActive && this.scroller"
			style="position: fixed; bottom: 0; right: 0"
			large
			color="info"
			icon
			v-on:click.stop="$vuetify.goTo(0)"
		>
			<v-icon>mdi-arrow-up-thin-circle-outline</v-icon>
		</v-btn>

		<!-- BOTTOM SHEET -->
		<v-bottom-sheet
			v-model="bottomSheet.isVisible"
			hide-overlay
			scrollable
			width="auto"
		>
			<v-sheet v-if="bottomSheet.success"
				><v-btn icon large @click="bottomSheet.isVisible = false"
					><v-icon color="success">mdi-thumb-up</v-icon></v-btn
				></v-sheet
			>
			<v-card v-else class="mx-auto" max-height="150px" dark>
				<v-card-title>
					<v-spacer />
					<v-btn right icon @click="bottomSheet.isVisible = false"
						><v-icon>mdi-close-circle-outline</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="text-center">
					{{ bottomSheet.message }}
				</v-card-text>
			</v-card>
		</v-bottom-sheet>
	</v-app>
</template>

<script>
	import lodash from "lodash";
	import { mapGetters } from "vuex";
	import SmartNavBar from "./components/SmartNavBar.vue";

	export default {
		name: "App",
		components: { SmartNavBar },
		data: () => ({
			miniVariant: false,
			scroller: false,
			tenantName: process.env.VUE_APP_TENANT_NAME,
			appName: process.env.VUE_APP_APP_NAME,
			navItems: [
				{
					id: 0,
					text: "Dashboard",
					routeName: "Home",
					icon: "mdi-view-dashboard-outline",
				},
				{
					id: 2,
					text: "Producten",
					icon: "mdi-book-open-blank-variant",

					children: [
						{
							id: 201,
							label: "Product catalogus",
							routeName: "ProductCatalog",
						},
						{
							id: 202,
							label: "Product categorieën",
							routeName: "CatalogIndex",
						},
					],
				},
				{
					id: 3,
					text: "Merken",
					icon: "mdi-watermark",
					routeName: "Brands",
				},

				{
					id: 4,
					text: "Workflows",
					icon: "mdi-list-status",
					children: [
						{ id: 401, label: "Onboarding", routeName: "Onboarding" },
						{
							id: 402,
							label: "Available To Promise",
							routeName: "ATP",
						},
					],
				},
				/*
																																																																																												{
																																																																																													id: 5,
																																																																																													text: "Instellingen",
																																																																																													icon: "mdi-cogs",
																																																																																												},
																																																																																												*/
			],
		}),
		computed: {
			...mapGetters({
				sessionActive: "session/sessionActive",
				bottomSheet: "session/bottomSheet",
				bgColor: "session/bgColor",
			}),
			drawer: {
				get() {
					return this.$store.getters["session/navigationDrawer"];
				},
				set(drawer) {
					this.$store.dispatch("session/setNavigationDrawer", drawer);
				},
			},
		},
		methods: {
			onResize() {
				let fx = lodash.debounce(this.setContentHeight, 1000);
				fx();
			},
			onScroll() {
				this.scroller = window.pageYOffset > 256;
			},
			setContentHeight() {
				this.$store.dispatch("session/updateUI", {
					contentHeight:
						window.innerHeight -
						this.$vuetify.application.top -
						this.$vuetify.application.footer,
				});
			},
			toggleDrawer() {
				if (this.$vuetify.breakpoint.mobile) {
					if (!this.drawer) {
						this.drawer = true;
						this.miniVariant = false;
					} else {
						this.drawer = false;
					}
				}
			},
			toggleVariant() {
				if (this.$vuetify.breakpoint.mobile) {
					this.drawer = false;
				} else {
					this.miniVariant = !this.miniVariant;
				}
			},
		},
	};
</script>

<style>
	/*** SCROLLBAR ***/
	/* width */
	::-webkit-scrollbar {
		width: 8px;
	}
	/* Track */
	::-webkit-scrollbar-track {
		background: #eceff1; /* blue-grey lighten-5  */
	}
	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #b0bec5; /* blue-blue-grey lighten-5  */
	}
	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #78909c; /* blue-grey lighten-1 */
	}

	/*** TRANSITIONS ***/
	.fade-enter {
		opacity: 0;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.75s ease-out;
	}

	.fade-leave-to {
		opacity: 0;
	}

	.slide-fade-enter {
		transform: translateX(10px);
		opacity: 0;
	}

	.slide-fade-enter-active,
	.slide-fade-leave-active {
		transition: all 0.25s ease;
	}

	.slide-fade-leave-to {
		transform: translateX(-10px);
		opacity: 0;
	}
</style>
