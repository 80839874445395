<style scoped>
	.bg {
		background-image: url("../assets/login.jpg");
		height: 50%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
	}
	.cntnr {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
</style>

<template>
	<div class="bg" v-if="!sessionActive">
		<v-card
			height="400px"
			width="400px"
			min-height="50vh"
			max-height="70vh"
			max-width="90vw"
			class="cntnr d-flex flex-column justify-space-between"
		>
			<v-sheet>
				<v-card-title>{{ tenantName }} {{ appName }}</v-card-title>
				<v-card-subtitle
					>Meld je aan met email en wachtwoord</v-card-subtitle
				>
			</v-sheet>
			<v-card-text>
				<v-form @keyup.native.enter="submitLogin">
					<v-text-field
						v-model="userName"
						type="text"
						placeholder="Gebruiker"
						clearable
						prepend-icon="mdi-account-circle"
						single-line
						hide-details
					/>
					<v-text-field
						v-model="userPwd"
						:type="showPassword ? 'text' : 'password'"
						placeholder="Wachtwoord"
						single-line
						hide-details
						prepend-icon="mdi-lock"
						:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
						@click:append="showPassword = !showPassword"
					/>
					<p v-if="error" class="red--text text-center my-4">
						{{ errMsg }}
					</p>
				</v-form>
			</v-card-text>
			<v-sheet>
				<v-card-actions>
					<v-btn
						block
						:loading="loading"
						color="primary"
						@click.stop="submitLogin"
					>
						Aanmelden
					</v-btn>
				</v-card-actions>
				<v-card-actions>
					<v-btn x-small block plain @click.stop="resetPassword"
						>Wachtwoord vergeten?</v-btn
					>
				</v-card-actions>
			</v-sheet>
		</v-card>
	</div>
</template>

<script>
	import { fireAuth, signInWithEmailAndPassword } from "@/services/firebase";
	import { mapGetters } from "vuex";

	export default {
		name: "Login",
		data: () => ({
			userName: "",
			userPwd: "",
			showPassword: false,
			error: false,
			errMsg: "",
			loading: false,
			appName: process.env.VUE_APP_APP_NAME,
			tenantName: process.env.VUE_APP_TENANT_NAME,
		}),
		computed: {
			...mapGetters({
				sessionActive: "session/sessionActive",
			}),
		},
		methods: {
			submitLogin() {
				this.loading = true;
				let that = this;
				window.setTimeout(function () {
					that.login();
				}, 1000);
			},
			login() {
				signInWithEmailAndPassword(fireAuth, this.userName, this.userPwd)
					.then(() => {
						this.$router.push({ name: "Home" });
					})
					.catch((oError) => {
						this.error = true;
						this.errMsg =
							"Dit is een ongeldige login: " + oError.message;
					})
					.finally(() => {
						this.loading = false;
					});
			},
			resetPassword() {
				this.$router.push({ name: "ResetPassword" });
			},
		},
		mounted() {
			this.$vuetify.goTo(0);
		},
	};
</script>

