import caas from "@/services/caas";

// STATE
const state = {
    firebaseUser: {},
    webSessionId: null,
    userInfo: {},
    dashboardInfo: [],
    ui: {
        contentHeight: null,
        backgroundColor: "amber lighten-5",
        navigationDrawer: null,
    },
    bottomSheet: {
        message: "",
        isVisible: false,
        color: "info",
        success: null,
    },
};
// ACTIONS
const actions = {
    startWebSession: async (context) => {
        const response = await caas.rpc("startWebSession", {
            userId: context.state.firebaseUser.uid,
            tenantId: Number(process.env.VUE_APP_TENANT_ID),
        });
        if (response.data.success) {
            context.commit(
                "SET_WEBSESSION_ID",
                response.data.success.webSessionId
            );
        }
    },
    endWebSession: async () => {
        const response = await caas.rpc("endWebSession");
        location.reload();
        return response;
    },
    fetchDashboardInfo: async (context) => {
        const response = await caas.rpc("getPortalUserDashboardInfo", {});
        context.commit(
            "SET_DASHBOARD_INFO",
            response.data.success.data.dashboardInfo
        );
    },
    updateUI: (context, payload) => {
        context.commit("SET_CONTENT_HEIGHT", payload.contentHeight);
    },
    showBottomMessage: (context, message) => {
        var timeout = 5000;
        context.commit("SET_BOTTOM_MESSAGE", message);
        context.commit("SET_BOTTOM_SUCCESS", null);
        context.commit("SHOW_BOTTOM_MESSAGE", true);
        window.setTimeout(function () {
            context.commit("SHOW_BOTTOM_MESSAGE", false);
        }, timeout);
    },
    showSuccess: (context) => {
        var timeout = 1000;
        context.commit("SET_BOTTOM_MESSAGE", "");
        context.commit("SET_BOTTOM_SUCCESS", true);
        context.commit("SHOW_BOTTOM_MESSAGE", true);
        window.setTimeout(function () {
            context.commit("SHOW_BOTTOM_MESSAGE", false);
        }, timeout);
    },
    setNavigationDrawer: (context, drawer) => {
        context.commit("SET_NAVIGATION_DRAWER", drawer);
    },
};
// MUTATIONS
const mutations = {
    SET_BOTTOM_MESSAGE: (state, pMessage) => {
        state.bottomSheet.message = pMessage;
    },
    SET_BOTTOM_SUCCESS: (state, pSuccess) => {
        state.bottomSheet.success = pSuccess;
    },
    SHOW_BOTTOM_MESSAGE: (state, pShow) => {
        state.bottomSheet.isVisible = pShow;
    },
    SET_DASHBOARD_INFO: (state, payload) => {
        state.dashboardInfo = payload;
    },
    SET_NAVIGATION_DRAWER: (state, drawer) => {
        state.ui.navigationDrawer = drawer;
    },
    SET_WEBSESSION_ID: (state, payload) => {
        state.webSessionId = payload;
    },
    SET_CONTENT_HEIGHT: (state, pContentHeight) => {
        state.ui.contentHeight = pContentHeight;
    },
    SET_FIREBASE_USER: (state, payload) => {
        state.firebaseUser = payload;
    },
    SET_USER_INFO: (state, payload) => {
        state.userInfo = payload;
    },
};
// GETTERS
const getters = {
    bgColor: (state) => {
        return state.ui.backgroundColor;
    },
    bottomSheet: (state) => {
        return state.bottomSheet;
    },
    contentHeight: (state) => {
        return state.ui.contentHeight;
    },
    navigationDrawer: (state) => {
        return state.ui.navigationDrawer;
    },
    sessionActive: (state) => {
        if (state && state.firebaseUser && state.webSessionId) {
            return true;
        }
        return false;
    },
    webSessionId: (state) => {
        return state.webSessionId;
    },
    firebaseUserId: (state) => {
        if (state.firebaseUser) {
            return state.firebaseUser.uid;
        }
        return null;
    },
    firebaseUserEmail: (state) => {
        if (state.firebaseUser) {
            return state.firebaseUser.email;
        }
    },
    dashboardInfo: (state) => {
        return state.dashboardInfo;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
