import Vue from "vue";
import App from "@/App.vue";
import router from "@/router/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import moment from "moment";
import { fireAuth } from "@/services/firebase";

Vue.config.productionTip = false;

Vue.filter("toDate", function (value) {
    if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
    }
});

Vue.filter("toCurrency", function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
    });
    return formatter.format(value);
});

Vue.filter("toNumber", function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat("nl-BE", {
        minimumFractionDigits: 2,
    });
    return formatter.format(value);
});

/* wait for firebase to determine the auth state */
let app;
fireAuth.onAuthStateChanged((user) => {
    store.commit("session/SET_FIREBASE_USER", null);
    if (user) {
        store.commit("session/SET_FIREBASE_USER", user);
    }
    // now mount the vue app (once)
    if (!app) {
        app = new Vue({
            router,
            store,
            vuetify,
            render: (h) => h(App),
        }).$mount("#app");
    }
});
