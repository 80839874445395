<template>
	<v-container class="pa-0" fluid>
		<!-- HEADER -->
		<v-row no-gutters :style="stickyTop" class="white">
			<v-container class="pa-0">
				<v-toolbar flat dense extended>
					<v-toolbar-title>Dashboard</v-toolbar-title>
					<template v-slot:extension>
						<v-tabs v-model="tabId" hide-slider show-arrows>
							<v-tab key="0">Deze week</v-tab>
							<v-tab key="1">Workflows</v-tab>
						</v-tabs>
						<v-spacer />
						<span
							v-show="!$vuetify.breakpoint.mobile"
							style="width: 200px"
							class="
								my-auto
								text-caption
								font-weight-light
								text-right
							"
							>{{ lastUpdated }}</span
						>
						<v-icon class="ml-3" @click.stop="refreshDashboard"
							>mdi-refresh</v-icon
						>
					</template>
				</v-toolbar>
			</v-container>
			<!-- DIVIDER -->
			<v-col cols="12">
				<v-progress-linear
					v-if="loading"
					active
					indeterminate
					rounded
				></v-progress-linear>
				<v-divider v-else class="accent" style="padding-bottom: 2px" />
			</v-col>
		</v-row>
		<!-- CONTENT -->
		<v-container v-if="mounted">
			<v-tabs-items v-model="tabId">
				<!-- DASHBOARD WEEK -->
				<v-tab-item key="0">
					<v-container>
						<v-row>
							<!-- SALES ORDERS -->
							<v-col cols="12" sm="6">
								<!-- SUMMARY -->
								<v-card flat outlined class="mb-3">
									<v-card-title>
										Orders deze week
									</v-card-title>
									<v-card-text
										class="
											d-inline-flex
											flex-column flex-md-row
										"
									>
										<v-text-field
											class="text-h6 ma-3"
											:value="
												salesOrders.orderSummary
													.orderValue | toCurrency
											"
											label="Omzet"
											outlined
											readonly
											flat
											hide-details
										/>
										<v-text-field
											class="text-h6 ma-3"
											:value="
												salesOrders.orderSummary
													.orderCount
											"
											label="#Orders"
											outlined
											readonly
											hide-details
										/>
										<v-text-field
											class="text-h6 ma-3"
											:value="
												salesOrders.orderSummary
													.customerCount +
												' [' +
												salesOrders.orderSummary
													.newCustomerCount +
												']'
											"
											label="#Klanten"
											outlined
											readonly
											hide-details
										/>
									</v-card-text>
								</v-card>
								<!-- BY DAY -->
								<v-card flat outlined class="mb-3">
									<v-card-title>
										Orders per dag
									</v-card-title>
									<v-card-text>
										<v-data-table
											:headers="ordersByDayHeaders"
											:items="salesOrders.ordersByDay"
											disable-pagination
											disable-sort
											hide-default-footer
											dense
										>
											<template
												v-slot:[`item.orderDay`]="{
													item,
												}"
											>
												<span
													class="
														text-caption
														font-weight-bold
													"
													>{{ item.orderDay }}</span
												>
											</template>
											<template
												v-slot:[`item.orderValue`]="{
													item,
												}"
											>
												<span>{{
													item.orderValue | toCurrency
												}}</span>
											</template>

											<template
												v-slot:[`item.avgOrderValue`]="{
													item,
												}"
											>
												<span>{{
													item.avgOrderValue
														| toCurrency
												}}</span>
											</template>
										</v-data-table>
									</v-card-text>
								</v-card>
								<!-- BY TYPE -->
								<v-card flat outlined>
									<v-card-title>
										Orders per type
									</v-card-title>
									<v-card-text>
										<v-data-table
											:headers="ordersByTypeHeaders"
											:items="salesOrders.ordersByType"
											disable-pagination
											disable-sort
											hide-default-footer
											dense
										>
											<template
												v-slot:[`item.orderType`]="{
													item,
												}"
											>
												<span
													class="
														text-caption
														font-weight-bold
													"
													>{{ item.orderType }}</span
												>
											</template>
											<template
												v-slot:[`item.orderValue`]="{
													item,
												}"
											>
												<span>{{
													item.orderValue | toCurrency
												}}</span>
											</template>

											<template
												v-slot:[`item.avgOrderValue`]="{
													item,
												}"
											>
												<span>{{
													item.avgOrderValue
														| toCurrency
												}}</span>
											</template>
										</v-data-table>
									</v-card-text>
								</v-card>
							</v-col>
							<!-- EVENTS -->
							<v-col cols="12" sm="6">
								<v-card flat outlined class="fill-height">
									<v-card-title
										>Recente activiteiten
									</v-card-title>
									<v-card-subtitle>
										<v-sparkline
											:value="operations.values"
											:labels="operations.labels"
											smooth
											line-width="2"
										/>
									</v-card-subtitle>
									<v-card-text
										style="height: 55vh; overflow-y: auto"
									>
										<v-timeline dense>
											<v-timeline-item
												small
												v-for="event in eventList"
												:key="event.evt_idx"
												:color="
													event.evt_color
														? event.evt_color
														: 'secondary'
												"
											>
												<v-card
													flat
													class="grey lighten-5"
												>
													<v-card-title>
														<span
															class="
																text-subtitle-2
															"
															>{{
																event.evt_title
															}}</span
														>
														<v-spacer></v-spacer>
														<span
															class="
																text-subtitle-2
																font-weight-light
															"
															>{{
																event.evt_time
															}}</span
														>
													</v-card-title>
													<v-card-subtitle
														v-text="
															event.evt_subtitle
														"
													/>

													<v-card-text
														v-text="event.evt_text"
													></v-card-text>
												</v-card>
											</v-timeline-item>
										</v-timeline>
									</v-card-text>
								</v-card>
							</v-col>
						</v-row>
					</v-container>
				</v-tab-item>
				<!-- DASHBOARD WORKFLOWS -->
				<v-tab-item key="1">
					<v-row>
						<v-col cols="12" sm="6">
							<v-row
								><v-col cols="12">
									<v-card outlined
										><v-card-title
											>Items <v-spacer />
										</v-card-title>
										<v-data-table
											:loading="loading"
											:headers="dashboardHeaders"
											:items="dashboardItems"
											hide-default-footer
											disable-sort
										>
											<template
												v-slot:[`item.ready_perc`]="{
													item,
												}"
											>
												<span
													class="font-weight-medium"
												>
													{{ item.ready_perc }} %
												</span>
											</template>
										</v-data-table>
									</v-card>
								</v-col>
								<v-col cols="12">
									<v-card outlined
										><v-card-title
											>Producten <v-spacer />
										</v-card-title>
										<v-data-table
											:loading="loading"
											:headers="dashboardHeaders3"
											:items="dashboardItems3"
											hide-default-footer
											disable-sort
										>
											<template
												v-slot:[`item.ready_perc`]="{
													item,
												}"
											>
												<span
													class="font-weight-medium"
												>
													{{ item.ready_perc }} %
												</span>
											</template>
										</v-data-table>
									</v-card>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12" sm="6">
							<v-card outlined
								><v-card-title
									>Merken <v-spacer />
								</v-card-title>
								<v-data-table
									:loading="loading"
									:headers="dashboardHeaders2"
									:items="dashboardItems2"
									disable-sort
								>
									<template
										v-slot:[`item.ready_perc`]="{ item }"
									>
										<span class="font-weight-medium">
											{{ item.ready_perc }} %
										</span>
									</template>
								</v-data-table>
							</v-card>
						</v-col>
					</v-row>
				</v-tab-item>
			</v-tabs-items>
		</v-container>
	</v-container>
</template>
<script>
	import caas from "@/services/caas";
	import { mapGetters } from "vuex";
	export default {
		name: "Home",
		mounted() {
			this.getWeekDashboard().then(() => {
				this.mounted = true;
				this.getWorkflowDashboard();
			});
		},
		data() {
			return {
				eventList: [],
				operations: [],
				salesOrders: [],
				lastUpdated: "",
				loading: false,
				mounted: false,
				dashboardItems: [],
				dashboardHeaders: [
					{ text: "", value: "wf_process" },
					{ text: "TOTAAL", value: "total_count", align: "right" },
					{ text: "GESTART", value: "wip_count", align: "right" },
					{ text: "GEREED", value: "ready_count", align: "right" },
					{ text: "%GEREED", value: "ready_perc", align: "right" },
				],
				dashboardItems2: [],
				dashboardHeaders2: [
					{ text: "", value: "brand_name" },
					{ text: "TOTAAL", value: "total_count", align: "right" },
					{ text: "GESTART", value: "wip_count", align: "right" },
					{ text: "GEREED", value: "ready_count", align: "right" },
					{ text: "%GEREED", value: "ready_perc", align: "right" },
				],
				dashboardItems3: [],
				dashboardHeaders3: [
					{ text: "", value: "wf_process" },
					{ text: "TOTAAL", value: "total_count", align: "right" },
					{ text: "GESTART", value: "wip_count", align: "right" },
					{ text: "GEREED", value: "ready_count", align: "right" },
					{ text: "%GEREED", value: "ready_perc", align: "right" },
				],
				ordersByDayHeaders: [
					{ text: "", value: "orderDay" },
					{
						text: "Omzet",
						value: "orderValue",
						align: "right",
					},
					{
						text: "#Orders",
						value: "orderCount",
						align: "right",
					},
					{
						text: "Omzet/order",
						value: "avgOrderValue",
						align: "right",
					},
					{
						text: "#Klanten",
						value: "customerCount",
						align: "right",
					},
					{
						text: "#Nieuwe klanten",
						value: "newCustomerCount",
						align: "right",
					},
				],
				ordersByTypeHeaders: [
					{ text: "", value: "orderType" },
					{
						text: "Omzet",
						value: "orderValue",
						align: "right",
					},
					{
						text: "#Orders",
						value: "orderCount",
						align: "right",
					},
					{
						text: "Omzet/order",
						value: "avgOrderValue",
						align: "right",
					},
				],
				tabId: 0,
			};
		},
		computed: {
			...mapGetters({
				contentHeight: "session/contentHeight",
			}),
			stickyTop() {
				return {
					position: "sticky",
					top: this.$vuetify.application.top + 0 + "px",
					zIndex: 10,
				};
			},
		},
		methods: {
			refreshDashboard() {
				switch (this.tabId) {
					case 0:
						this.getWeekDashboard();
						break;
					case 1:
						this.getWorkflowDashboard();
						break;
				}
			},
			getWorkflowDashboard() {
				this.loading = true;
				const sdoName = "web/objects/dECatItem.r";
				let params = {
					action: "run",
					procName: "getWorkflowDashboard",
				};
				return caas.sdo(sdoName, params).then((response) => {
					if (response.data.success) {
						this.dashboardItems = response.data.success.data.wfPivot;
						this.dashboardItems2 =
							response.data.success.data.brandPivot;
						this.dashboardItems3 =
							response.data.success.data.productPivot;
					}
					this.loading = false;
				});
			},
			getWeekDashboard() {
				this.loading = true;
				return caas
					.rpc("getWeekDashboard", {})
					.then((response) => {
						if (response.data.success) {
							this.eventList = response.data.success.data.events.data;
							this.lastUpdated =
								response.data.success.data.events.lastUpdated;
							this.operations = response.data.success.data.operations;
							this.salesOrders =
								response.data.success.data.salesOrders;
						}
					})
					.finally(() => (this.loading = false));
			},
		},
	};
</script>

